import React, { useState } from 'react'
import { useLocation } from 'react-router-dom';
// import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'api/axios'
import ArticleList from './ArticleList'

export default function ArticleSearch() {
  // const navigate = useNavigate();
  const location = useLocation();
  const word = location.pathname.split('/')[3];
  const [articles, setArticles] = useState([{}]);
  // console.log(word)
  const getArticle = async () => {
    try {
      // get似乎和detail的put不太一样
      const res = await axios.get('/articles/search/' + word);
      if (Object.keys(res.data).length !== 0) {
        setArticles(res.data);
      } else {
        setArticles('');
      }
    } catch (err) {
      console.log('a', articles, 'a');
    }
  }

  getArticle();

  return (
    <div className='container article-container'>
      {articles ? <ArticleList articles={articles} isShowTag={true}/> : 
      <div>未搜索到该内容</div>}
    </div>
  )
}
