import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import axios from 'api/axios'
import ArticleList from 'pages/articles/ArticleList';
import AddArticle from 'components/AddArticle';
import { Helmet } from 'react-helmet';

export default function TagDetail() {
  const location = useLocation();
  const id = location.pathname.split('/')[2];
  const [articles, setArticles] = useState('');
  const [tagName, setTagName] = useState('');

  useEffect(() => {
    const getArticles = async () => {
      const res = await axios.get('/tags/' + id)
      setArticles(res.data)
    };
    getArticles();
    const getTagName = async () => {
      const res = await axios.get('/tags/name/' + id)
      setTagName(res.data.name)
    };
    getTagName();
  }, [id]);

  return (
    <div className='container article-container'>
      <Helmet>
        <title>{tagName} 标签页</title>
      </Helmet>
      <AddArticle tagId={id}/>
      <div className='tag-title'><span className='title-stressed'>{tagName}</span> 标签文章：</div>
      {
        articles.length > 0 ? <ArticleList articles={articles} isShowTag={false}/> : <div>该标签下还没有文章</div>
      }
    </div>
  )
}
