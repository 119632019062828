import cover from 'assets/cover.jpg';
import './miniapps.css'
import { useEffect } from 'react'

export default function MiniApps() {
  useEffect(() => {
    document.title = "小程序"
  })
  return (
    <div className="container miniapp-container">
      <div className='miniapp-card'>
        <img src={cover} className="miniapp-logo" alt="logo" />
        <div className='miniapp-title'>推送封面提取器</div>
        <div className="miniapp-description">一键提取<span className='stressed'>微信</span>推送封面、下载推送视频、提取<span className='stressed'>B站</span>封面</div>
      </div>
      {/* <div className='miniapp-card'>
        <img src={cover} className="miniapp-logo" alt="logo" />
        <div className='miniapp-title'>疯趣</div>
        <div className="miniapp-description">一键提取<span class='stressed'>微信</span>推送封面、下载推送视频、提取<span class='stressed'>B站</span>封面</div>
      </div> */}
    </div>
  );
}