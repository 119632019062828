import { useState } from "react"
import { Link } from "react-router-dom"
// import { Link, useNavigate } from "react-router-dom"
import Navbar from "./Navbar"

export default function Header() { 
  const [word, setWord] = useState('');
  // const navigate = useNavigate();

  function handleSearch(e: {key: string}) {
    if (e.key === 'Enter') {
      window.location.href='/articles/search/' + word;
      // navigate('/articles/search/' + word)
    }
  }

  return (
    <header className="header">
      <div>
        <Link className="home-link" to={'/'}>FeJiyu</Link>
      </div>
        <Navbar />
      <input className="search-box" placeholder="搜索..." value={word} onKeyDown={handleSearch} onChange={e => setWord(e.target.value)}></input>
    </header>
  )
}