import React, { useState, useEffect } from 'react';
import upload from 'assets/upload.png';

export default function UploadImage(props: { handleImageMD: (arg0: string) => void; }) {
  const [image, setImage] = useState('');
  const [imageName, setImageName] = useState('');

  async function handleImageUpload(e: any) {
    e.preventDefault();
    if (!image) return;
    let formData = new FormData();
    formData.append('file', image);

    await fetch('/api/upload', {
      method: 'POST',
      body: formData
    }).then((response) => response.json())
      .then((data) => {
        // ![drawing](drawing.jpg)
        const imageMD = `\n![${imageName}](${data})\n`;
        props.handleImageMD(imageMD);
    });
  }

  function handleImage(e: any) {
    console.log(e.target.files);
    const file = e.target.files[0];
    const fileName = file.name;

    const img = {
      preview: URL.createObjectURL(file),
      data: file,
    }
    setImage(img.data);
    // setImage(file);
    setImageName(fileName);
    //   for (var pair of formData.entries()) {
    //     console.log(pair[0]+ ', ' + pair[1]); 
    // }
  }
  
  useEffect(() => {
    if (image && imageName) {
      // console.log('yes') 自动点击按钮
      const element: HTMLElement | null = document.getElementById("uploadButton");
      if (element) element.click();
    }
  },[image, imageName]);

  // encType="multipart/form-data"
  return (
    <>
      <form className='upload-form' id='image-form' onSubmit={handleImageUpload}>
        {/* <div className='image-add'>上传图片
          <input className='image-input' type='file' name='file' accept="image/*" onChange={handleImage}></input>
        </div>
        <div className='image-upload' type='submit'>Upload</div> */}
        <div className='upload-card'>
          <img className='upload-icon' src={upload} alt=''></img>
          <input className='image-input' type='file' name='file' accept="image/*" onChange={handleImage}></input>
        </div>
        <button id='uploadButton' className='btn-hidden' type='submit'>Upload</button>
      </form>
    </>
  )
}