import React from 'react'
import { Link } from 'react-router-dom'

export default function PageNotFound() {
  return (
    <div className='page404'>
      <div className='title404'>404 PageNotFound</div>
      <Link to='/' className='btn btn-black'>返回主页</Link>
    </div>
  )
}