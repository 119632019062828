import { useEffect, useState } from 'react';
import { Link } from "react-router-dom";

export default function Tag(props: { tag: string }) {
  const tag = props.tag;
  const [tagName, setTagName] = useState('');
  useEffect(() => {
    if (tag && Object.keys(tag).length !== 0) {
      fetch('/api/tags/name/' + tag[0])
        .then(res => res.json())
        .then(data => {
          // console.log('na', data)
          setTagName(data.name);
        })
    }
  }, [tag])

  return (
    (
      <div className="artilce-tag">标签：
        <Link to={`/tags/${props.tag}`} className="tag-link">{tagName ? tagName : ''}</Link></div>
    )
  )
}