// import React from "react";
// import axios from "axios";
import axios from 'api/axios'
import { useEffect, useState, useContext } from "react";
import { useLocation,useNavigate } from "react-router-dom";
import './articles.css';
import { AuthContext } from 'context/AuthContext';
import Tag from "components/Tag";
import Select from "components/Select";
import UploadImage from 'components/UploadImage';
import fdate from 'api/fdate';
import { Helmet } from 'react-helmet'

export default function ArticleDetail() {
  const location = useLocation();
  const navigate = useNavigate();
  // [0] = '', [1] = 'articles, [2] = id
  const id = location.pathname.split('/')[2];
  const { auth } = useContext(AuthContext);

  const [article, setArticle] = useState('');
  const [title, setTitle] = useState('');
  const [markdown, setMarkdown] = useState('');
  const [updateMode, setUpdateMode] = useState(false);

  const [tag, setTag] = useState('');

  // console.log('article.tag', article.tag)

  useEffect(() => {
    const getArticle = async () => {
      try {
        const res = await axios.put('/articles/' + id);
        // console.log('res', res, 'res');
        const article = res.data;
        // console.log('1', article, '1');
        setArticle(article);
        setTag(article.tag[0]); // 初始化tag，以应对不修改tag直接提交的情况
      } catch (err) { // 处理id错误，即页面不存在的错误
        navigate('/404'); // back to 404 page
      }
    };
    getArticle();
    // const getAllTags = async () => {
    //   // const res = await
    // }
  }, [id, navigate]);
  
  // delete
  async function handleDelete() {
    if (window.confirm("确定要删除吗？")) {
      await axios.delete('/articles/' + id);
      navigate('/'); // back to home page
    }
  }

  function handleEdit() {
    setUpdateMode(true);
    setTitle(article.title);
    setMarkdown(article.markdown);
  }

  async function handleUpdate(e) {
    // console.log('tag', tag, 'tag')
    e.preventDefault();
    if (!title.trim() || !markdown.trim())
      return;
    await axios.put('/articles/' + article._id, {
      tag,
      title,
      markdown
    });
    // console.log(res.data.title)
    window.location.replace('/articles/' + article._id);
    // navigate('/articles/' + id); 
  }

  function handleSelect(data) {
    setTag(data);
    // console.log('sss value',e.target.value)
  }

  function handleImageMD(md) {
    setMarkdown(markdown + md);
  }

  // form如果模块化，相同代码较少，改变较多，实际上代码量也没又减少，因此暂不模块化
  return (
    <div className="container article-container">
      <Helmet>
        <title>{article.title}</title>
      </Helmet>
      {updateMode ?
        (
          <>
            <UploadImage handleImageMD={handleImageMD}></UploadImage>
            <form onSubmit={handleUpdate} className="form-group">
              <div className="btn-group-sticky">
                <button className="btn btn-black" onClick={() => setUpdateMode(false)}>Cancel</button>
                <button type="submit" className="btn">Update</button>
              </div>
              <Select handleSelect={handleSelect} tag={article.tag} />
              {/* 这里的tag是id */}
              <input
                value={title}
                required
                type="text"
                name="title"
                placeholder='Title'
                className="form-control title1"
                onChange={e => setTitle(e.target.value)}
              >
              </input>
              <textarea
                value={markdown}
                rows="20"
                required
                name="markdown"
                placeholder="Markdown Content"
                className="form-control"
                onChange={e => setMarkdown(e.target.value)}
              ></textarea>
            </form>
          </>
        ) : (
          <>
            {auth ?
              <div className="btn-group-sticky">
                <button className='btn btn-black' onClick={handleDelete}>删除</button>
                <button className='btn' onClick={handleEdit}>编辑</button>
              </div>
              : ''}
            <div className='detail-card'>

              <h4 className='article-title'>{article.title}</h4>
              <div className='article-info'>
                <div className='article-date'>日期：{fdate(article.createdAt)}</div>
                {/* <div className='article-date'>日期：{new Date(article.createdAt).toLocaleDateString()}</div> */}
                <div className='article-reading'>浏览量：{article.reading}</div>
                {/* <div className='article-like'>点赞：{article.like}</div> */}
                <Tag tag={article.tag} />
              </div>
              <div className='markdown' dangerouslySetInnerHTML={{ __html: article.pureHtml }}></div>
              {/* <div className='article-decription' dangerouslySetInnerHTML={{ __html: article.pureHtml }}></div> */}
            </div>
          </>
        )}
    </div>
  )
}