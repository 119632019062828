// import logo from 'logo.jpg';
import React, { useEffect, useMemo, useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import Header from 'components/Header';
import Footer from 'components/Footer'
import { AuthContext } from 'context/AuthContext';


import ArticlePage from 'pages/articles/ArticlePage'
import ArticleDetail from 'pages/articles/ArticleDetail';
import NewArticle from 'pages/articles/NewArticle';
import ArticleSearch from 'pages/articles/ArticleSearch';


import TagPage from 'pages/tags/TagPage'
import TagDetail from 'pages/tags/TagDetail';

import CalendarPage from 'pages/calendars/CalendarPage';
import MiniApps from 'pages/miniapps/MiniApps';
import Login from 'pages/login/Login';
// import { useState } from 'react';

// import ArticleList from 'pages/articles/ArticleList';
import PageNotFound from 'components/PageNotFound';
// import Refresh from 'components/Refresh';

function App() {
  const [auth, setAuth] = useState();
  const value = useMemo(() => ({ auth, setAuth }), [auth, setAuth]);
  useEffect(() => {
    const authStatus = localStorage.getItem('auth');
    authStatus && setAuth(authStatus);
  }, [])

  // const logged = true;
  return (
    <div className="App">
      <Header />
      {/* <Refresh /> */}
      {/* <Tags /> */}
      <AuthContext.Provider value={value}>
        <Routes>
          <Route path='/' element={<ArticlePage />} />
          <Route path='/articles' element={<ArticlePage />} />
          <Route path='/iamin' element={<Login />} />
          <Route path='/articles/new/*' element={auth ? <NewArticle /> : <Login />} />
          <Route path='/articles/:id' element={<ArticleDetail />} />
          <Route path='/articles/search/:word' element={<ArticleSearch />} />
          <Route path='/tags' element={<TagPage />} />
          <Route path='/tags/:name' element={<TagDetail />} />
          <Route path='/calendars' element={<CalendarPage />} />
          <Route path='/miniapps' element={<MiniApps />} />
          <Route path='/404' element={<PageNotFound />}></Route>
          <Route path='/*' element={<PageNotFound />}></Route>
          {/* <Route path='/' element={<ArticlePage />} />
          <Route path='articles' element={<ArticlePage />} />
          <Route path='iamin' element={<Login />} />
          <Route path='articles/new' element={auth ? <NewArticle /> : <Login />} />
          <Route path='articles/:id' element={<ArticleDetail />} />
          <Route path='/articles/search/:word' element={<ArticleSearch />} />
          <Route path='tags' element={<TagPage />} />
          <Route path='tags/:name' element={<TagDetail />} />
          <Route path='miniapps' element={<MiniApps />} />
          <Route path='*' element={<PageNotFound />}></Route> */}
        </Routes>
      </AuthContext.Provider>
      <Footer />
    </div>
  );
}

export default App;
