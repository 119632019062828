import ArticleList from "./ArticleList"
// import Footer from 'components/Footer'
import { useState, useEffect, useContext } from "react"
import { Link, useLocation } from 'react-router-dom';
import { AuthContext } from 'context/AuthContext';

export default function ArticlePage(props) {
  const { auth } = useContext(AuthContext);
  const [articles, setArticles] = useState([{}]);
  const location = useLocation();

  const path = location.pathname.split('/')[1];
  // 在useEffect内用fetch会更容易
  useEffect(() => {
    fetch('/api/articles/')
      .then(res => res.json())
      .then(data => {
        setArticles(data);
      });
    if (path === 'articles')
      document.title = '文章页'
    else
      document.title = "FeJiyu - 编程笔记"
  }, [path])


  return (
    <>
    <div className='container article-container'>
      {auth ?
        <div className="btn-group-sticky">
          <Link to={'/articles/new'} className='btn'>新文章</Link>
        </div>
        : ""}
      <ArticleList articles={articles} isShowTag={true}/>
    </div>
    {/* <Footer /> */}
    </>
  )
}
