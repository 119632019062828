import { Link } from 'react-router-dom';
import './articles.css'
import fdate from 'api/fdate';
import { useState, useEffect } from 'react';

// 接受文章列表作为输入的参数
export default function ArticleList(props) {
  const articles = props.articles;
  const [tags, setTags] = useState(new Map());

  useEffect(() => {
    fetch('/api/tags')
      .then(res => res.json())
      .then(data => {
        let tags = new Map();
        for (const item of data) {
          tags.set(item._id, item.name);
        }
        setTags(tags);
        // console.log(tags);
      })
  });

  function extractAbstract(markdown) {
    if (markdown) {
      const subMD = markdown.substring(0, 1150); // extract first 30 characters
      // 顺序有影响`要在前面 replace # - ` with ''，将图片格式中的![ ]()部分转为空字符
      const abstract = subMD.replace(/[`>#-]|!\[|\](.*)/g, '');
      return abstract;
    }
  }

  const list = articles.map((article, i) => (
    <Link key={i} to={`/articles/${article._id}`} className='article-link'>
      <div className='article-card'>
        {/* <Link to={{pathname: `/articles/${article._id}`, state: article}} className='article-link'> */}
        <h4 className='article-title'>{article.title}</h4>
        <div className='article-decription'>{extractAbstract(article.markdown)}</div>
        <div className='article-info'>
          <div className='article-date'>日期：{fdate(article.createdAt)}</div>
          <div className='article-reading'>浏览量：{article.reading}</div>
          {/* <div className='article-like'>点赞：{article.like}</div> */}
          {/* 这里的article.tag直接输出看起来是字符串，clg输出是['sda']，但是数据类型是object，因此+''来转为字符串 */}
          {props.isShowTag ? <div className="artilce-tag">标签：{tags.get(article.tag + '')}</div> : ''}
          {/* {props.isShowTag ? <Tag tag={article.tag}/> : '' } */}
        </div>
      </div>
    </Link>
  ))

  return (
    <>
      <div className='arcticle-list'>
        {list}
      </div>
    </>
  )
}