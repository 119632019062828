import { useContext } from "react"

import { Link } from 'react-router-dom';
import { AuthContext } from 'context/AuthContext';

export default function AddArticle(props) {
  const { auth } = useContext(AuthContext);

  return (
    <>
      {auth ?
        <div className="btn-group-sticky">
          <Link to={'/articles/new/' + props.tagId} className='btn'>新文章</Link>
        </div>
        : ""}
    </>
  )
}
