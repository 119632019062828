// import FormFields from "pages/articles/FormFields"
import { useState } from 'react'
import axios from 'api/axios'
import { Navigate, Link, useLocation } from 'react-router-dom'
import Select from 'components/Select';
import UploadImage from 'components/UploadImage';
import { Helmet } from 'react-helmet';


export default function NewArticle() {
  const [title, setTitle] = useState('');
  const [markdown, setMarkdown] = useState('')
  const [article, setArticle] = useState('')
  const [tag, setTag] = useState('');

  const location = useLocation();
  const tagId = location.pathname.split('/')[3];

  async function handleSubmit(e) {
    e.preventDefault()
    if (!title.trim() || !markdown.trim())
      return;
    // save date to db
    try {
      await axios.post('/articles', {
        tag: tag || tagId, // 从标签新增，且默认不修改tag时，导致不会调用handleTag， 从而不能返回tag，因此直接对tag赋值tagId，否则使用返回的tag
        title,
        markdown
      }).then(res => {
        // console.log('d', res.data, 'd')
        // if (res.status === 200) {
        //   console.log('saved')
        // }
        setArticle(res.data)
        // window.location.replace("/articles/" + res.data._id);
      });
    } catch (err) {
      console.log('err', err)
    }
    // nav to article detail page
  }

  function handleSelect(tag) {
    setTag(tag);
  }

  function handleImageMD(md) {
    setMarkdown(markdown + md);
  }

  return (
    <div className="container article-container">
      <Helmet>
        <title>新建文章</title>
      </Helmet>
      {article && <Navigate to={'/articles/' + article._id}></Navigate>}
      <UploadImage handleImageMD={handleImageMD}></UploadImage>
      <form onSubmit={handleSubmit} className="form-group">
        <div className='btn-group-sticky'>
          <Link to={'/'} className="btn btn-black">Cancel</Link>
          {/* <button to={'/'} className="btn btn-black">Cancel</button> */}
          <button type="submit" className="btn save">Save</button>
        </div>
        {/* 两种情况，假如是从主页的新建，则tagId为空 */}
        <Select handleSelect={handleSelect} tag={tagId ? [tagId] : ""} />
        {/* <Select handleSelect={handleSelect} /> */}
        
        {/* <Select handleSelect={handleSelect} paramTag={paramTag}/> */}
        <input
          value={title}
          required
          type="text"
          name="title"
          placeholder='Title'
          className="form-control title1"
          onChange={e => setTitle(e.target.value)}
        >
        </input>
        <textarea
          value={markdown}
          rows="20"
          required
          name="markdown"
          placeholder="Markdown Content"
          className="form-control"
          onChange={e => setMarkdown(e.target.value)}
        ></textarea>
      </form>
      
    </div>
  )
}