import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import './calendars.css';
// import React, { useEffect, useState } from 'react';

export default function CalendarPage() {
  let months = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
  const d = new Date();
  let year = d.getFullYear(), month = d.getMonth(), date = d.getDate(), day = d.getDay();
  let monthArr = new Array(35).fill(0), dayStart = 0;
  console.log(year, month + 1, date, day);
  if ((year % 4 === 0 && year % 100 !== 0) || (year % 400 === 0)) {
    months[1] = 29;
  }
  // date = 2;
  // day = 5;
  // dayStart = (day - (date % 7 - 1) + 7) % 7;
  dayStart = (day - date % 7 + 8) % 7;
  // console.log(dayStart);
  for (let i = dayStart - 1, j = 0; j < months[month]; i++, j++) {
    monthArr[i] = j + 1;
  }
  // console.log(monthArr);
  // console.log(months);
  // const [calendar, setCalender] = useState('');
  const weekArr = ['一', '二', '三', '四', '五', '六', '七'];
  const weekList = weekArr.map(i => <div key={i} className='cell'>{i}</div>)
  let calendar = monthArr.map((i, idx) => {
    if (i) {
      return <Link to={`/calendars/${year}-${month}-${i}`} key={idx} className='cell'>{i}</Link>;
    } else {
      return <div key={idx} className='cell'>{' '}</div>;
    }
  });
  // let calendar = monthArr.map((i) => i ? <div key={i} className='cell'>{i}</div> : '')

  useEffect(() => {
    // const d = new Date(); 
    // const date = d.getDate(), youbi = d.getDay();
  }, []);
  return (
    <div className='container calender-container'>
      <div className='calendar'>
      {weekList}
      {calendar}
        {/* <div className='week'>{weekList}</div>
        <div>{calendar}</div> */}
      </div>
    </div>
  )
}
