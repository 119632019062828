import './tags.css'
import axios from 'api/axios'
import { AuthContext } from 'context/AuthContext';
import { useState, useEffect, useContext } from "react"
import { Link } from 'react-router-dom';

export default function TagPage(props) {
  const { auth } = useContext(AuthContext);
  const [tags, setTags] = useState([{}]);
  const [tag, setTag] = useState('');

  useEffect(() => {
    fetch('/api/tags')
      .then(res => res.json())
      .then(data => {
        setTags(data)
      })
      document.title = "标签列表"
  })

  async function handleSubmit(e) {
    e.preventDefault();
    if (!tag.trim()) return;
    try {
      await axios.post('/tags', {
        name: tag
      }).then(res => {
        setTags([...tags, res.data])
      });
    } catch (err) {
      console.log('err', err)
    }
  }

  const tagsList = tags.map((tag, i) => (
    <div key={i} className="tag-card">
      <Link id={tag.id} to={'/tags/' + tag._id} className='tag-item'>{tag.name}</Link>
      {/* <Link id={tag.id} to={'/tags/' + tag._id} className='tag-item'>{tag.name}</Link> */}
    </div>
  ))

  return (
    <div className='container tag-container'>
      {auth ?
        <>
          <form onSubmit={handleSubmit} className="tag-group">
            <input required 
              value={tag} 
              className='form-control tag-input' 
              onChange={e => setTag(e.target.value)} 
              placeholder='输入新标签'>
            </input>
            <div className="btn-group-sticky">
              {/* <button className='btn btn-black'>编辑</button> */}
              <button className='btn'>增加</button>
            </div>
          </form>
        </>
        : ""}
      <div className='tag'>
        {tagsList}
      </div>
    </div>
  )
}