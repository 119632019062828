import { useMatch, useResolvedPath, Link } from "react-router-dom"

export default function Navbar() {
  return (
    <nav className="header-bar">
      <ul>
        <CustomLink to='/articles'>文章</CustomLink>
        <CustomLink to='/tags'>标签</CustomLink>
        {/* <CustomLink to='/calendars'>日历</CustomLink> */}
        <CustomLink to='/miniapps'>小程序</CustomLink>
        {/* <CustomLink to='/nihongo'>日语</CustomLink> */}
      </ul>
    </nav>
  )
}

// function CustomLink({to, children, ...props}) {
// function CustomLink(to: string, children : any, ...props: any) {
  function CustomLink({to, children, ...props}) {
  const resolvePath = useResolvedPath(to)
  const isActive = useMatch({path: resolvePath.pathname, end: true})

  return (
    <li className={isActive ? 'active' : ''}>
      <Link to={to} {...props}>
        {children}
      </Link>
    </li>
  )
}