import axios from 'api/axios'
import './login.css'
// import { useContext } from 'react'
import { useContext, useState } from 'react'
import { AuthContext } from 'context/AuthContext';


export default function Login() {
  const { auth, setAuth } = useContext(AuthContext);
  // const [auth, setUser] = useState();
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')

  async function handleSubmit(e) {
    e.preventDefault();
    try {
      // const res = await axios.get('/auth/login');    
      // http://localhost:3002
      await axios.post('/auth/login', {
        username, 
        password
      }).then(res => {
        // console.log('r', res.status === 200, 'r')
        if (res.status === 200) {
          setAuth(true);
          localStorage.setItem('auth', true);
        }
      });
    } catch (err) {
      console.log('err', err);
    }
  };

  function handleLogout() {
    setAuth(false);
    localStorage.clear();
  }
  return (
    <>
      {auth ?
        (
          <div className="login">
            {/* <div className='login-title'>Logout</div> */}
            <button className="login-button" onClick={handleLogout}>Logout</button>
          </div>
        ) :
        (
          <div className="login">
            <div className='login-title'>Login</div>
            <form className="login-form" onSubmit={handleSubmit}>
              <div className="login-group">
                <label className='login-label' htmlFor='username'>Username</label>
                {/* <input className='login-input' id='nickname' placeholder='nickname...'></input> */}
                <input 
                  className='login-input' 
                  value={username} 
                  onChange={e => setUsername(e.target.value)} 
                  autoComplete="username" name='username' id='username' placeholder='username...'></input>
              </div>
              <div className="login-group">
                <label className='login-label' htmlFor="password">Password</label>
                {/* <input className='login-input' id='password' type="password" placeholder='password...'></input> */}
                <input 
                  className='login-input' 
                  value={password} 
                  onChange={e => setPassword(e.target.value)}  
                  autoComplete="current-password" name='password' id='password' type="password" placeholder='password...'></input>
              </div>
              <button className="login-button" type='submit'>Login</button>
            </form>
          </div>
        )
      }
    </>
  )
}
