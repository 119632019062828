import axios from "api/axios";
import { useState, useEffect } from "react"


export default function Footer() {
  const [reading, setReading] = useState('');
  const [year, setYear] = useState(0);
  console.log(new Date())
  console.log(reading)
  // 未指定数据无法直接put???
  useEffect(() => {
    const getVisit = async () => {
      const res = await axios.put('/visit/')
      const visit = res.data;
      setReading(visit.reading);
      // console.log('aaa', article ,'aa')
      // setArticle(article);
      // setTag(article.tag[0]); // 初始化tag，以应对不修改tag直接提交的情况
    };
    getVisit();
    setYear((new Date()).getFullYear());
  }, []);

  return (
    <footer className="footer">
      <div className="reading">累计访问次数：{ + reading}</div>
      <div className="copyright">© FeJiyu {year}</div>
      {/* <div className="copyright">© FeJiyu 2023</div> */}
      <a
          className="filing-link"
          href="https://beian.miit.gov.cn/"
          target="_blank"
          rel="noopener noreferrer"
      >
        京ICP备2022003937号-1
      </a>
    </footer>
  );
}