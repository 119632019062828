import { useState, useEffect } from 'react'

// export default function Select(props: { tag: any[]; handleSelect: (arg0: string) => void; }) {
export default function Select(props: { tag: string[]; handleSelect: (arg0: { _id: string, name: string, length: number}) => void; paramTag: string}) {
  const [tags, setTags] = useState<{ _id: string, name: string, length: number}[]>();
  // 两种情况，标签页新增的参数tag或者编辑页的tag
  // const [selectValue, setSelectValue] = useState(props.paramTag || (props.tag && props.tag[0])); // id
  
  const [selectValue, setSelectValue ] = useState(props.tag && props.tag[0]); // id
  // props.tag[0]
  const [tagList, setTagList] = useState('');
    // console.log(selectValue)
    // console.log(props.tag, 'props.tag', selectValue)
  useEffect(() => {
    fetch('/api/tags/')
      .then(res => res.json())
      .then(data => {
        // console.log(tags)
        setTags(data);
        // setTagList(data.map((tag: any[], i: number) => {
        setTagList(data.map((tag: { _id: string; name: string}, i: number) => {
          // console.log('p', props.tag)
          return (
            <option key={i} value={tag._id}>{tag.name}</option>
          )
        }
        ))
      })
  }, [])


  function handleTag(e: { target: { value: any; }; }) {
    const id = e.target.value;
    console.log('dddddddd', id, 'ddddddddddd')
    // console.log(id)
    if (tags) {
      for (let i = 0; i < tags.length; i++) {
        if (tags[i]._id === id) {
          console.log('tags[i]', tags[i])
          props.handleSelect(tags[i]);
          // console.log(tags[i])
          setSelectValue(tags[i]._id);
          return;
        }
      }
    }
  }

  return (
    <>
    {/* 两种情况，标签页新增的参数tag或者编辑页的tag */}
      {
        props.tag ?
        // 从标签新增时，默认不修改，导致不会调用handleTag， 从而不能返回tag
          <select value={ selectValue } required className='tag-select' onChange={handleTag}>
          {/* <select value="html" required className='tag-select' onChange={handleTag}> */}
            {/* value是id */}
            {tagList}
          </select>
          // props.paramTag ?  || 
          : 
          <select required className='tag-select' onChange={handleTag}>
            <option value="">选择标签</option>
            {tagList}
          </select>
      }
    </>
  )
}

